<template>
  <ModalMain @close="close()">
    <div class="px-4 md:px-7 pt-4 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 class="text-3xl uppercase tracking-widest mt-4 text-center animate-slide-down-fade-in01s">Check your network</h1>
          <div class="text-slate-100 mt-6 animate-slide-down-fade-in04s text-center">
            <p v-show="!switched">
              This site only supports the Ethereum <span class="text-white font-bold">{{ network }}</span> network.
            </p>
            <p v-show="switched" class="animate-slide-down-fade-in04s">
              Please switch the network in your wallet application to continue.
            </p>
          </div>
          <ButtonButton
            :is-loading="switched"
            :disabled="switched"
            type="button"
            class="block w-full mt-8"
            @click.prevent.stop="checkNetwork()"
          >
            Switch
          </ButtonButton>
        </div>
      </article>
    </div>
  </ModalMain>
</template>
<script>
import { mapWritableState, mapState, mapActions } from 'pinia';

import { NETWORKS } from '@/core/wallet/networks';
import { getConfig } from '@/utils/getConfig';

export default defineComponent({
  name: 'ModalNetworkMain',
  data() {
    return {
      switched: false,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['showNetworkModal',]),
    ...mapState(useAuthStore, ['wallet',]),
    network() {
      const networkId = getConfig('ETHEREUM_NETWORK_ID');
      return NETWORKS[networkId];
    },
  },
  mounted() {
    this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'Network', });
  },
  methods: {
    ...mapActions(useWalletProviderStore, ['disconnect',]),
    async close() {
      await this.disconnect();
      this.showNetworkModal = false;
    },
    async checkNetwork() {
      if (!this.wallet?.connected) {
        this.close();
        return;
      }
      try {
        this.switched = true;
        await this.wallet.checkChain();
      } catch (err) {
        useNuxtApp().$rollbar.error('Unable to request network switch::', err);
      }
    },
  },
});
</script>
